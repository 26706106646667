import { defineStore } from 'pinia'

export const useAppStore = defineStore('appStore', () => {
  const collapsed = ref(false)

  const setCollapsed = (value: boolean) => {
    collapsed.value = value
  }

  return {
    collapsed,
    setCollapsed
  }
})
